import React, { useCallback, useEffect, useMemo, memo, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FiUsers } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import {
  Alert,
  Col,
  Nav,
  ProgressBar,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { useAuth } from "../utils/Context";
import { Bar } from "react-chartjs-2";
/* eslint-disable */
import Chart from "chart.js/auto";
/* eslint-enable */
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { ReactFloatingBalloons } from "react-floating-balloons";
import { Link } from "react-router-dom";
// import { ReactFloatingBalloons } from "react-floating-balloons";

const Dashboard = ({ menuWidth }) => {
  const { role_id, id } = useAuth();
  const [data, setData] = useState({
    punch_status: "",
  });
  const [show, setShow] = useState(false);
  const [punchOut, setPunchOut] = useState(false);
  // const [adminShow, setAdminShow] = useState(false);
  const handlePunchOutClose = () => setPunchOut(false);
  const handlePunchOutShow = () => setPunchOut(true);
  const [punchOut1, setPunchOut1] = useState(false);
  const handlePunchOutClose1 = () => setPunchOut1(false);
  const handlePunchOutShow1 = () => setPunchOut1(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showbreak, setShowbreak] = useState(false);
  const [endBreak, setEndBreak] = useState(false);
  const handleEndBreak = () => setEndBreak(false);
  const handleBreakStart = () => setEndBreak(true);

  const [breakData, setBreakData] = useState([]);
  const handleBreakClose = () => {
    setShowbreak(false);
    setValidated(false);
    setErrorMessage(false);
  };

  const handleBreak = () => setShowbreak(true);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [punchInData, setPunchInData] = useState({
    work_type: 1,
    in_punch_note: "",
  });
  const [punchOutData, setPunchOutData] = useState({
    out_punch_note: "",
    error: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const admintonggle = () => {
  //   setAdminShow(!adminShow);
  // };

  const fetchData = useCallback(() => {
    if (role_id) {
      setLoading(true);
      var formdata = new FormData();
      formdata.append("employee_id", id);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/dashboard`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setData(result.data);
          }
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  }, [role_id, id]);

  useEffect(() => {
    fetchData();
  }, [role_id, id, fetchData]);

  const [greeting, setGreeting] = useState("");

  const data1 = useMemo(
    () => [
      [0, 12, "Good morning"],
      [12, 18, "Good afternoon"],
      [18, 24, "Good evening"],
    ],
    []
  );

  useEffect(() => {
    const hr = new Date().getHours();
    for (let i = 0; i < data1.length; i++) {
      if (hr >= data1[i][0] && hr < data1[i][1]) {
        setGreeting(data1[i][2]);
        break;
      }
    }
  }, [data1]);

  const handlePunchin = () => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("employee_id", id);
    formdata.append("work_type", punchInData.work_type);
    formdata.append("in_punch_note", punchInData.in_punch_note);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/punch-in`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleClose();
          fetchData();
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const handlePunchout = () => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("employee_id", id);
    formdata.append("out_punch_note", punchOutData.out_punch_note);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/punch-out`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handlePunchOutClose();
          fetchData();
        } else {
          setPunchOutData({ ...punchOutData, error: result.message });
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/break-list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setBreakData(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, []);

  const [startBreak, setStartBreak] = useState({
    break_id: "",
    start_break_note: "",
  });
  const handleStartBreak = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    var formdata = new FormData();
    formdata.append("employee_id", id);
    formdata.append("break_id", startBreak.break_id);
    formdata.append("start_break_note", startBreak.start_break_note);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/break-start`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setStartBreak(startBreak);
          handleBreakClose();
          fetchData();
          fetchData1();
          setValidated(false);
        } else {
          setErrorMessage(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handlebreakEnd = () => {
    var formdata = new FormData();
    formdata.append("employee_id", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/break-end`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleEndBreak();
          fetchData();
          fetchData1();
          window.location.reload();
        }
      })
      .catch((error) => console.log("error", error));
  };
  /* eslint-disable */
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  /* eslint-enable */
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentDateTime(new Date());
  //   }, 1000);
  //   return () => clearInterval(intervalId);
  // }, []);
  const formattedDate = new Intl.DateTimeFormat("en-GB").format(
    currentDateTime
  );
  const formattedTime = currentDateTime.toLocaleTimeString();

  const [getAnnouncements, setGetAnnouncements] = useState([]);

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_announcements_date_wise`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setGetAnnouncements(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, []);

  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_admin_dashboard_data`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDashboardData(result.data);
          setChartSeries([
            result.data.total_attendance_early,
            result.data.total_attendance_regular,
            result.data.total_attendance_late,
          ]);

          const colors = ["rgb(234, 131, 58)", "#33FF57", "rgb(223, 71, 54)"];
          setChartOptions({
            chart: {
              width: 100,
              type: "donut",
            },
            plotOptions: {
              pie: {
                startAngle: -90,
                endAngle: 270,
              },
            },
            dataLabels: {
              enabled: false,
            },
            fill: {
              type: "gradient",
            },
            legend: {
              formatter: function (val, opts) {
                return val + " - " + opts.w.globals.series[opts.seriesIndex];
              },
              position: "bottom",
            },
            labels: ["Early", "Regular", "Late"],
            colors: colors,
          });
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "none",
      },
      title: {
        display: false,
      },
    },
    height: 200,
  };

  const data2 = {
    labels: ["Permanent", "Probation"],
    datasets: [
      {
        label: "",
        data: [
          dashboardData.permanent_employess,
          dashboardData.terminated_employess,
        ],
        backgroundColor: "#019aff",
        barThickness: 30,
      },
    ],
  };

  const data3 = {
    labels: [
      "Software Engineer",
      "Project Manager",
      "HR Manager",
      "General Manager",
    ],
    datasets: [
      {
        label: "",
        data: [
          dashboardData.manager_employess,
          dashboardData.director_employess,
          dashboardData.ceo_employess,
          dashboardData.total_sales_manager,
        ],
        backgroundColor: "#019aff",
        barThickness: 30,
      },
    ],
  };
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [profileInfo, setProfileInfo] = useState({});

  const fetchData1 = useCallback(() => {
    if (id) {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/get_user_profile_information?user_id=${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setProfileInfo(result.data);
          }
          setLoading(false);
        })
        .catch((error) => console.error(error));
    }
  }, [id]);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);

  const [accept, setAccept] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userBirthday, setUserBirthday] = useState(new Date());
  const isUserBirthday = (today, date_of_birth) => {
    return (
      today.getDate() === date_of_birth.getDate() &&
      today.getMonth() === date_of_birth.getMonth()
    );
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/birthday_wish`)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          const userBirthdayDate = new Date(result.data.date_of_birth);
          setUserBirthday(userBirthdayDate);
          setUserData(result.data);
        }
      })
      .catch((error) => console.log("Error fetching user birthday:", error));
  }, []);

  useEffect(() => {
    const today = new Date();
    if (isUserBirthday(today, userBirthday)) {
    }
  }, [userBirthday]);

  const handleAcceptClose = () => {
    setAccept(false);
  };



  
  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      {userData.length > 0 &&
        userData.map((item) => (
          <div className="scroll-left" key={item.id}>
            <h5 className="">
              🎉Today is {item.first_name} {item.last_name} birthday! 🎉
            </h5>
            <ReactFloatingBalloons
              count={10}
              msgText={`Happy birthday ${item.first_name} ${item.last_name} !!`}
              colors={["yellow", "purple", "green", "red", "blue", "orange"]}
              popVolumeLevel={0.5}
            />
          </div>
        ))}

        {/* {data.status === 1 ? 
          "1": "2"
        } */}
      {role_id === "2" ? (
        <div className="d-lg-flex justify-content-between align-items-center pt-4 w-100">
          <h4 className="mt-1">Dashboard</h4>
          <div>
            {!loading ? (
              <span>
                {data.punch_status === 0 ? (
                  <button
                    className="btn btn-success puch_btn mt-1"
                    onClick={handleShow}
                  >
                    Punch In
                  </button>
                ) : (
                  <span>
                    {data.punch_status   === 1 || data.punchData && data.punchData.is_manually_punched_out === 0 ? (
                      <button
                        className="btn btn-warning puch_btn mt-1"
                        onClick={handlePunchOutShow}
                      >
                        Punch Out
                      </button>
                    ) : (
                      <button
                        className="btn btn-warning puch_btn mt-1"
                        onClick={handlePunchOutShow1}
                      >
                        Punch Out
                      </button>
                    )}
                    &nbsp; &nbsp;
                    {data.punch_status === 1 || data.punchData && data.punchData.is_manually_punched_out === 0 ? (
                      <span>
                        {data.break_status === 0 || data.break_status === 2 ? (
                          <button
                            className="btn btn-primary puch_btn mt-1"
                            onClick={handleBreak}
                          >
                            Take a break
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary puch_btn mt-1"
                            onClick={handleBreakStart}
                          >
                            End break
                          </button>
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                )}
              </span>
            ) : (
              <Spinner animation="grow" size="sm" />
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <>
        {role_id === "1" ? (
          <div>
            <Row className="justify-content-between">
              <Col md={3} sm={6} xs={12} className="my-3">
                <Link to="/employee/list" className="card text-decoration-none">
                  <div className="sidebar_icons_admin text-white">
                    <FiUsers />
                  </div>
                  <div className="px-3">
                    <p className="mb-0 small">
                      {dashboardData && dashboardData.total_emplyees}
                    </p>
                    <h6 className="mb-0 small text-muted">Total Employee</h6>
                  </div>
                </Link>
              </Col>
              <Col md={3} sm={6} xs={12} className="my-3">
                <Link
                  to="/administration/departments"
                  className="card text-decoration-none"
                >
                  <div className="sidebar_icons_admin text-white">
                    <FiUsers />
                  </div>
                  <div className="px-3">
                    <p className="mb-0 small">
                      {dashboardData && dashboardData.total_departments}
                    </p>
                    <h6 className="mb-0 small text-muted">Total departments</h6>
                  </div>
                </Link>
              </Col>
              <Col md={3} sm={6} xs={12} className="my-3">
                <Link to="/leave/request" className="card text-decoration-none">
                  <div className="sidebar_icons_admin text-white">
                    <FiUsers />
                  </div>
                  <div className="px-3">
                    <p className="mb-0 small">
                      {dashboardData && dashboardData.total_leave_requests}
                    </p>
                    <h6 className="mb-0 small text-muted">
                      Total leave requests
                    </h6>
                  </div>
                </Link>
              </Col>
              <Col md={3} sm={6} xs={12} className="my-3">
                <Link to="/leave/request" className="card text-decoration-none">
                  <div className="sidebar_icons_admin text-white">
                    <FiUsers />
                  </div>
                  <div className="px-3">
                    <p className="mb-0 small">
                      {dashboardData && dashboardData.on_leave_today}
                    </p>
                    <h6 className="mb-0 small text-muted">On leave today</h6>
                  </div>
                </Link>
              </Col>
            </Row>
            <Row className=" justify-content-between">
              <Col className="col-md-9 mb-3">
                <div className="attendance" style={{ height: "465px" }}>
                  <div className="text-right">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <div className="justify-content-between align-items-center d-lg-flex p-3">
                        <h3 className="time_admin">Employee Statistics</h3>
                        <Nav variant="pills" className="d-flex">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              className="tab_css_leave"
                            >
                              By employment status
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="second"
                              className="tab_css_leave"
                            >
                              By Designation
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <Bar options={options} data={data2} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <Bar options={options} data={data3} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third"></Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </Col>
              <Col className="col-md-3">
                <div className="attendance p-3" style={{ height: "465px" }}>
                  <h4 className="time_admin">
                    Total Attendance Today -
                    {dashboardData && dashboardData.total_attendance}{" "}
                  </h4>
                  <ReactApexChart
                    options={chartOptions}
                    series={chartSeries}
                    type="donut"
                  />
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="mt-3">
            <Row>
              <Col className="col-md-8 col-sm-12">
                <Row>
                  <Col className="">
                    <div className="statistics d-block p-4 mb-4">
                      {data.punchData && data.punchData.in_time && (
                        <div className="ribbon">
                          <span>
                            {data.punchData &&
                            data.punchData.in_time >= "09:30" &&
                            data.punchData.in_time <= "09:45:59" ? (
                              <div className="bg-primary">ON time</div>
                            ) : data.punchData &&
                              data.punchData.in_time < "09:30" ? (
                              <div className="bg-success">Early</div>
                            ) : (
                              <div className="bg-danger">Late</div>
                            )}
                          </span>
                        </div>
                      )}
                      <p className="sgt_admin">
                        Hi, {data && data.first_name} {data && data.last_name} ({data && data.id})
                      </p>
                      <h5 className="sgt_heading">{greeting}</h5>
                      <div className="d-flex mt-5">
                        <div className="d-flex">
                          <div className="rounded-circle punch_download_admin">
                            <BsDownload />
                          </div>
                          <div className="mx-2">
                            {data.punchData && data.punchData.in_time ? (
                              <h6 className="m-0">{data.punchData.in_time}</h6>
                            ) : (
                              <h6 className="m-0">Not yet</h6>
                            )}
                            <p className="m-0">Punch in time</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="rounded-circle punch_download_admin2">
                            <BsDownload />
                          </div>
                          <div className="mx-2">
                            {data.punchData && data.punchData.out_time ? (
                              <h6 className="m-0">{data.punchData.out_time}</h6>
                            ) : (
                              <h6 className="m-0">Not yet</h6>
                            )}
                            <p className="m-0">Punch out time</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={12} sm={12} xs={12} className="mt-2">
                    <div className="card justify-content-between p-4">
                      <div>
                        <p className="admin_left_font">0</p>
                        <h5 className="sgt_admin">Total leave allowance</h5>
                      </div>
                      <div>
                        <p className="admin_left_font d-flex align-items-center">
                          <span className="admin_box bg-primary mx-1"></span>
                          Paid - 0
                        </p>
                        <p className="admin_left_font d-flex align-items-center mb-0">
                          <span className="admin_box bg-success mx-1"></span>
                          <span>Un paid - 0</span>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12} className="mt-2">
                    <div className="card justify-content-between p-4">
                      <div>
                        <p className="admin_left_font">
                          {profileInfo && profileInfo.total_leaves_taken}
                        </p>
                        <h5 className="sgt_admin">Total leave taken</h5>
                      </div>
                      <div>
                        <p className="admin_left_font d-flex align-items-center">
                          <span className="admin_box bg-primary mx-1"></span>
                          Paid -
                          {profileInfo && profileInfo.total_paid_leaves_taken}
                        </p>
                        <p className="admin_left_font d-flex align-items-center mb-0">
                          <span className="admin_box bg-success mx-1"></span>
                          <span>
                            Un paid -{" "}
                            {profileInfo &&
                              profileInfo.total_unpaid_leaves_taken}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4 mb-4">
                  <Col lg={6} md={12} sm={12} xs={12} className="mt-2">
                    <div className="card justify-content-between p-4">
                      <div>
                        <p className="admin_left_font">
                          {profileInfo && profileInfo.total_leaves_available}
                        </p>
                        <h5 className="sgt_admin">Total leave available</h5>
                      </div>
                      <div>
                        <p className="admin_left_font d-flex align-items-center">
                          <span className="admin_box bg-primary mx-1"></span>
                          Paid -{" "}
                          {profileInfo &&
                            profileInfo.total_leaves_available_paid}
                        </p>
                        <p className="admin_left_font d-flex align-items-center mb-0">
                          <span className="admin_box bg-success mx-1"></span>
                          <span>
                            Un paid -{" "}
                            {profileInfo &&
                              profileInfo.total_leaves_available_unpaid}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12} className="mt-2">
                    <div className="card justify-content-between p-4">
                      <div>
                        <p className="admin_left_font">
                          {profileInfo &&
                            profileInfo.total_leaves_request_pending}
                        </p>
                        <h5 className="sgt_admin">Leave request pending</h5>
                      </div>
                      <div>
                        <p className="admin_left_font d-flex align-items-center">
                          <span className="admin_box bg-primary mx-1"></span>
                          Paid -{" "}
                          {profileInfo &&
                            profileInfo.total_leaves_request_pending_paid}
                        </p>
                        <p className="admin_left_font d-flex align-items-center mb-0">
                          <span className="admin_box bg-success mx-1"></span>
                          <span>
                            Un paid -{" "}
                            {profileInfo &&
                              profileInfo.total_leaves_request_pending_unpaid}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="col-md-4 col-sm-12 ">
                <div className=" attendance">
                  <h3 className="time_admin  p-3 border-bottom">Time Log</h3>
                  <div className="p-4">
                    <h5 className="sgt_heading border-bottom">Today</h5>
                    <div className="d-flex justify-content-between">
                      <div className="text-center">
                        <p className="mb-0">09:30</p>
                        <p className="mb-0 admin_left_font text-muted">
                          Scheduled
                        </p>
                      </div>
                      <div className="text-center">
                        <p className="mb-0">
                          {profileInfo.today &&
                            profileInfo.today.total_worked_time}
                        </p>
                        <p className="mb-0 admin_left_font text-muted">
                          Worked
                        </p>
                      </div>
                      <div className="text-center">
                        <p className="mb-0">
                          {profileInfo.today &&
                            profileInfo.today.total_break_time}
                        </p>
                        <p className="mb-0 admin_left_font text-muted">Break</p>
                      </div>
                      <div className="text-center">
                        <p className="mb-0">
                          {profileInfo.today &&
                            profileInfo.today.total_remaining_time}
                        </p>
                        {profileInfo.today &&
                        profileInfo.today.today_balance_and_extra_time === 0 ? (
                          <p className="mb-0 admin_left_font text-muted">
                            Balance
                          </p>
                        ) : (
                          <p className="mb-0 admin_left_font text-muted">
                            Extra
                          </p>
                        )}
                      </div>
                    </div>

                    {profileInfo.today?.break_list?.length > 0 ? (
                      profileInfo.today.break_list.map((item, index) => (
                        <div
                          key={item.id || index}
                          className="d-flex justify-content-between mt-3"
                        >
                          <p className="mb-0 admin_left_font text-muted">
                            Break start time : <b>{item.start_time} </b>
                          </p>
                          <p className="mb-0 admin_left_font text-muted">
                            Break End time : <b>{item.end_time}</b>
                          </p>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}

                    <div>
                      <h5 className="sgt_heading border-bottom mt-4">
                        This Month
                      </h5>
                      <div className="d-flex mt-3">
                        <div className="sidebar_icons_admin text-white">
                          <FiUsers />
                        </div>
                        <div className="px-3">
                          <p className="sgt_heading mb-0">
                            {profileInfo.month &&
                              profileInfo.month.total_schedule_time}
                          </p>
                          <p className="mb-0 admin_left_font text-muted">
                            Total time schedule
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="mt-3 admin_left_font">
                          Worked time -{" "}
                          {profileInfo.month &&
                            profileInfo.month.total_working_time}
                        </p>
                        <ProgressBar
                          animated
                          now={
                            profileInfo.month &&
                            profileInfo.month.total_working_time
                          }
                        />
                        <p className="mt-3 admin_left_font">
                          Break time -{" "}
                          {profileInfo.month &&
                            profileInfo.month.total_break_time}
                        </p>
                        <ProgressBar
                          animated
                          now={
                            profileInfo.month &&
                            profileInfo.month.total_break_time
                          }
                        />
                        <p className="mt-3 admin_left_font">
                          {profileInfo.month &&
                          profileInfo.month.total_remaining_hours === 0 ? (
                            <span className="mb-0 admin_left_font text-muted">
                              Extra
                            </span>
                          ) : (
                            <span className="mb-0 admin_left_font text-muted">
                              Balance
                            </span>
                          )}{" "}
                          -{" "}
                          {profileInfo.month &&
                            profileInfo.month.total_remaining_hours}
                        </p>
                        <ProgressBar
                          animated
                          now={
                            profileInfo.month &&
                            profileInfo.month.total_remaining_hours
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="attendance mt-4 p-4 border-bottom">
              <h4>Announcements</h4>
              <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th
                        track-by="0"
                        className="datatable-th py-4 px-3 table_color text-center"
                      >
                        <span className="font-size-default">
                          <span>Title</span>
                        </span>
                      </th>
                      <th
                        track-by="1"
                        className="datatable-th py-4 px-3 table_color text-center"
                      >
                        <span className="font-size-default">
                          <span>Start Date</span>
                        </span>
                      </th>
                      <th
                        track-by="2"
                        className="datatable-th py-4 px-3 table_color text-center"
                      >
                        <span className="font-size-default">
                          <span>End Date</span>
                        </span>
                      </th>
                      <th
                        track-by="3"
                        className="datatable-th py-4 px-3 table_color text-center"
                      >
                        <span className="font-size-default">Description</span>
                      </th>
                    </tr>
                  </thead>
                  {getAnnouncements.length > 0 ? (
                    <tbody>
                      {getAnnouncements &&
                        getAnnouncements.map((item) => (
                          <tr className="text-center" key={item.id}>
                            <td>{item.title}</td>
                            <td>{item.start_date}</td>
                            <td>{item.end_date}</td>
                            <td
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </tr>
                        ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="6">
                          <div className="pt-5 pb-5">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p className="small text-secondary">
                              Please add a new entity or manage the data table
                              to see the content here <br />
                              Thank you
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        )}
      </>
      {/*------------------punchin popup----------------------*/}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Punch In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="d-flex">
              <div className="rounded-circle punch_download">
                <BsDownload />
              </div>
              <div className="mx-2">
                <h6>Punch In date & time:</h6>
                <h5>Today ( {formattedTime})</h5>
                <p className="mb-0">{formattedDate}</p>
              </div>
            </div>
            <p className="w-100 py-3 px-2 mt-2 ip_color">
              <b>IP:</b> {data.ip_address}
            </p>
            <Form.Group className="mb-3">
              <Form.Label>Punch In note</Form.Label>
              {punchInData && (
                <Form.Control
                  as="textarea"
                  placeholder="Punch In note here"
                  rows={3}
                  value={punchInData.in_punch_note}
                  onChange={(e) =>
                    setPunchInData({
                      ...punchInData,
                      in_punch_note: e.target.value,
                    })
                  }
                />
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary puch_btn" onClick={handleClose}>
            Cancel
          </button>
          {!loading ? (
            <button
              className="btn btn-success puch_btn"
              onClick={handlePunchin}
            >
              Punch In
            </button>
          ) : (
            <button className="btn btn-success puch_btn">
              Please Wait.....
            </button>
          )}
        </Modal.Footer>
      </Modal>
      {/*------------------punchout popup----------------------*/}
      <Modal show={punchOut} onHide={handlePunchOutClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Punch Out</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="d-lg-flex justify-content-between">
              <div className="d-flex">
                <div className="rounded-circle punch_download2 ">
                  <BsDownload />
                </div>

                <div className="mx-2">
                  <h6>Punch Out date & time:</h6>
                  <h5> Today ({formattedTime})</h5>
                  <p>{formattedDate}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="rounded-circle punch_download">
                  <BsDownload />
                </div>
                <div className="mx-2">
                  <h6>Punch In date & time:</h6>
                  {data.punchData && <h5>Today ({data.punchData.in_time})</h5>}
                  {data.punchData && (
                    <p className="mb-0">{data.punchData.punch_date || ""}</p>
                  )}
                  {/* {data.punchData && (
                      <p className="mb-0">{data.punchData.work_type || ""}</p>
                    )} */}

                  {/* {data.punchData && (
                        <div>
                    {data.punchData.work_type === 2 ? (
                      <label>
                        <input
                          type="radio"
                          name="work_type"
                          id="workfromoffice"
                          value={1}
                          checked={data.punchData.work_type === 1  ? true : false  }
                        />
                        <span className="mx-2">Work From Office</span>
                      </label>
                    ) : (
                      <label>
                        <input
                          type="radio"
                          name="work_type"
                          id="workfromhome"
                          value={2}
                          checked={data.punchData.work_type === 2  ? true : false}
                        />
                        <span className="mx-2">Work From Home</span>
                      </label>
                    )} 
                    </div>
                      )} */}
                </div>
              </div>
            </div>
            <p className="w-100 py-3 px-2 ip_color">
              <b>IP:</b> {data.ip_address}
            </p>
            <Form.Group className="mb-3">
              <Form.Label>Punch In note</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Punch In note here"
                rows={3}
                value={punchOutData.out_punch_note}
                onChange={(e) =>
                  setPunchOutData({
                    ...punchOutData,
                    out_punch_note: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        {punchOutData.error && (
          <Alert variant="danger" className="m-2">
            {punchOutData.error}
          </Alert>
        )}

        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handlePunchOutClose}
          >
            Cancel
          </button>
          {!loading ? (
            <button
              className="btn btn-warning puch_btn"
              onClick={handlePunchout}
            >
              Punch Out
            </button>
          ) : (
            <button className="btn btn-warning puch_btn">
              Please Wait....
            </button>
          )}
        </Modal.Footer>
      </Modal>

      {/*------------------Take break popup----------------------*/}
      <Modal show={showbreak} onHide={handleBreakClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Start Break</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleStartBreak}>
            {breakData.map((item) => (
              <div key={item.id}>
                <label className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="workType"
                    value={item.id}
                    onChange={(e) => {
                      setStartBreak({
                        ...startBreak,
                        break_id: e.target.value,
                      });
                    }}
                    className="mx-1 my-1"
                    style={{ height: "20px", width: "20px" }}
                    required
                  />
                  {item.name}
                </label>
              </div>
            ))}
            <div className="mt-3 mb-3">
              <label>Purpose:</label>
              <Form.Control
                type="text"
                placeholder="Add purpose"
                value={startBreak.start_break_note}
                onChange={(e) =>
                  setStartBreak({
                    ...startBreak,
                    start_break_note: e.target.value,
                  })
                }
              />
            </div>
            <div className="mt-1">
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            </div>
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleBreakClose}
                type="button"
              >
                Close
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Start
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/*------------------Take break end popup----------------------*/}
      <Modal show={endBreak} onHide={handleEndBreak} size="md">
        <Modal.Header closeButton>
          <Modal.Title>End Break</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h3>End Break</h3>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleEndBreak}
          >
            Close
          </button>
          <button className="btn btn-primary puch_btn" onClick={handlebreakEnd}>
            End
          </button>
        </Modal.Footer>
      </Modal>
      {/* ----------Happy birthday popup---------------- */}

      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <IoMdCheckmarkCircleOutline className="jobdisk_icon text-success" />
            {userData.length > 0 &&
              userData.map((item) => (
                <h5 key={item.id}>Happy Birthday {item.name}🎂</h5>
              ))}
            <p></p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-success puch_btn"
              onClick={handleAcceptClose}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      {/* ---------after punchout popup---------------- */}

      <Modal
        show={punchOut1}
        onHide={handlePunchOutClose1}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <IoMdCheckmarkCircleOutline className="jobdisk_icon text-success" />
            <h4>Already Punch Out</h4>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-success puch_btn"
              onClick={handlePunchOutClose1}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
    // </>
  );
};
export default memo(Dashboard);
