import React, { useEffect, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import {  useNavigate } from "react-router-dom";
import { useAuth } from "../utils/Context";
import { Modal } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";

const DailyWorkReport = ({ menuWidth }) => {

  const { id } = useAuth();
  const navigate = useNavigate();

  const navigateToContacts = () => {
    navigate("/adddailyworkreport");
  };
  const [workListuser, setWorkListuser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [project_id, setProjectId] = useState("");
  const [report_start_date, setReportStartDate] = useState("");
  const [report_end_date, setReportEndDate] = useState("");
  const [accept, setAccept] = useState(false);
  const handleAccept = (work_report_id) => {
    setDeleted({ work_report_id });
    setAccept(true);
  };

  const handleAcceptClose = () => setAccept(false);

  useEffect(() => {
    setLoading(true);
    const fetchWorkList = () => {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/get_user_work_reports?user_id=${id}&project_id=${project_id}&report_start_date=${report_start_date}&report_end_date=${report_end_date}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setWorkListuser(result.data);
            setTotaltime(result.total_time);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching work list:", error);
        });
    };

    fetchWorkList();
  }, [id, project_id, report_start_date, report_end_date]);

  const [deleted, setDeleted] = useState({
    work_report_id: "",
  });

  const handleDelete = () => {
    var formdata = new FormData();
    formdata.append("work_report_id", deleted.work_report_id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/delete_work_report`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          // alert(result.message);
          setDeleted(!deleted);
          window.location.reload();
          handleAcceptClose();
        } else alert("Deletion failed: " + result.message);
      })
      .catch((error) => console.log("error", error));
  };
  
  const [totalTime, setTotaltime] = useState();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/project-list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setProjectList(result.data);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const reload = () => {
    window.location.reload();
  };
  const handlestatusShow = (userId ) => {
    navigate(`/editdailyworkreport/${userId}`);
  };



  const formatDate = (dateString) => {
    if (!dateString) return "Not added yet";
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };
  
  const today = new Date();
  const formattedToday = formatDate(today);

  // console.log(formattedToday);
  return (

    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4>Daily Work Report</h4>
        <div>
          <button
            className="btn btn-success puch_btn"
            onClick={navigateToContacts}
          >
            Add Daily Work Report
          </button>
        </div>
      </div>
      <div>
        <div className="d-lg-flex justify-content-between align-items-center flex-wrap">
          <select
            className="tab_css text-uppercase"
            value={project_id}
            onChange={(e) => setProjectId(e.target.value)}
          >
            <option selected disabled value="">
              Select Project Name
            </option>
            {projectList.map((item) => (
              <option key={item.id} value={item.id}>{item.project_name}</option>
            ))}
          </select> 
          From Date
          <input
            type="date"
            className="tab_css"
            name="from Date"
            value={report_start_date}
            onChange={(e) => setReportStartDate(e.target.value)}
          />
          To Date
          <input
            type="date"
            className="tab_css"
            name="to Date"
            value={report_end_date}
            onChange={(e) => setReportEndDate(e.target.value)}
          />
          &nbsp;
          <button className="btn btn-primary puch_btn" onClick={reload}>
            Reset
          </button>
        </div>
      </div>

      <div className="table-responsive custom-scrollbar table-view-responsive bg-white px-4 py-primary mt-3">
        {!loading ? (
          <table className="table mb-0">
            <thead>
              <tr>
                <th className=" table_color_width py-3"> S.No.</th>
                <th className=" table_color py-3 ">Project&nbsp;Name</th>
                <th className=" table_color py-3">Date</th>
                <th className="table_color py-3">From&nbsp;Time</th>
                <th className="table_color_width py-3"> To&nbsp;Time</th>
                <th className="table_color py-3">Time Spend</th>
                <th className=" table_color_css py-3"> Work Report</th>
                <th className="table_color_width py-3">Status</th>
                <th className=" table_color py-3">Remark</th>
                <th className=" table_color_width py-3">Action</th>
              </tr>
            </thead>
          
              <tbody>
                {workListuser.map((item) => (
                  <tr key={item.work_report_id}>
                    <td>{item.sr_no}</td>
                    <td>{item.project_name}</td>
                    <td>{item.work_report_date}</td>
                    <td>{item.from_time}</td>
                    <td>{item.to_time}</td>
                    <td>{item.time_spent}</td>
                    <td
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <td>
                      {item.status === "1"
                        ? "Complete"
                        : item.status === "2"
                        ? "Pending"
                        : item.status === "3"
                        ? "Under process"
                        : ""}
                    </td>
                    <td>{item.remark}</td>
                    <td>
        {(item.work_report_date) === formattedToday ? (
          <div className="d-flex">
            <span className="hover_btn">
              <FaEdit
                onClick={() => handlestatusShow(item.work_report_id)}
                className="left_icon_css"
              />
            </span>
            &nbsp; &nbsp;
            <span className="hover_btn">
              <RiDeleteBin5Line
                onClick={() => handleAccept(item.work_report_id)}
                className="left_icon_css text-danger"
              />
            </span>
          </div>
        ) : (
          "" // Empty placeholder for when the condition is not met
        )}
      </td>
                  </tr>
                ))}
              </tbody>
          
           { workListuser.length > 0 ? 
            <tfoot>
              <th>Total</th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <th>{totalTime}</th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {/* <td></td> */}
            </tfoot>
            :
            <tbody>
            <tr className="text-center">
      <td colSpan="9" >
      <div className="pt-5 pb-5">
        <img
          src="/no_data.svg"
          alt="no data"
          width="150px"
        />
        <h6>Nothing to show here</h6>
        <p className="small text-secondary">
          Please add a new entity or manage the data table to see the
          content here <br/>Thank you
        </p>
      </div>
      </td>
          </tr>
      </tbody>
            }
          </table>
        ) : (
          <div className="spinner_height">
            {/* <Spinner animation="border" size="lg" /> */}
            <div className="loader"></div>
          </div>
        )}
      </div>

      {/* ----------------delete popup ----------------*/}
      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to delete this work report</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleAcceptClose}
            >
              No
            </button>
            <button
              className="btn btn_color puch_btn"
              onClick={() => handleDelete(deleted.work_report_id)}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default DailyWorkReport;
