import React, { useEffect, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
// import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/Context";
import { Modal } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
const AllEmployeeReport = ({ menuWidth }) => {

  const { role_id } = useAuth();
  const [workList, setWorkList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [project_id, setProjectId] = useState("");
  // const [report_date, setReportDate] = useState("");
  const [report_start_date, setReportStartDate] = useState("");
  const [report_end_date, setReportEndDate] = useState("");
  const [accept, setAccept] = useState(false);
  const [employee_id, setEmployeeid] = useState("");
  const handleAccept = (work_report_id) => {
    setDeleted({ work_report_id });
    setAccept(true);
  };
  const handleAcceptClose = () => setAccept(false);


  const [data1, setData1] = useState([]);
  useEffect(() => {
    fetchData2();
  }, []);


  const fetchData2 = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/all-employee-list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData1(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };



  useEffect(() => {
    if (role_id === '1' ) {
    setLoading(true);
    const fetchWorkList = () => {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/get_all_work_reports?user_id=${employee_id}&project_id=${project_id}&report_start_date=${report_start_date}&report_end_date=${report_end_date}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setWorkList(result.data);
            setTotaltime(result.total_time);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching work list:", error);
        });
  }
    fetchWorkList();
}
  }, [project_id, report_start_date, report_end_date,role_id,employee_id]);
  const [deleted, setDeleted] = useState({
    work_report_id: "",
  });

  const handleDelete = () => {
    var formdata = new FormData();
    formdata.append("work_report_id", deleted.work_report_id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/delete_work_report`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDeleted(!deleted);
          window.location.reload();
          handleAcceptClose();
        } else alert("Deletion failed: " + result.message);
      })
      .catch((error) => console.log("error", error));
  };
  
  const [totalTime, setTotaltime] = useState();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/project-list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setProjectList(result.data);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const reload = () => {
    window.location.reload();
  };

  return (

    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4>All Employee Daily Work Report</h4>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <select
            className="tab_css text-uppercase p-2 select_css_employee"
            value={project_id}
            onChange={(e) => setProjectId(e.target.value)}
          >
            <option selected disabled value="">
              Select Project Name
            </option>
            {projectList.map((item) => (
              <option key={item.id} value={item.id}>{item.project_name}</option>
            ))}
          </select>

          <select
            className="tab_css text-uppercase p-2 select_css_employee"
            value={employee_id}
            onChange={(e) => setEmployeeid(e.target.value)}
          >
            <option selected disabled value="">
            Select a Employee
            </option>
            {data1.map((item) => (
              <option key={item.id} value={item.id}>{item.first_name} {item.last_name}</option>
            ))}
          </select>
   
          From Date
          <input
            type="date"
            className="tab_css p-2"
            name="from Date"
            value={report_start_date}
            onChange={(e) => setReportStartDate(e.target.value)}
          />
          To Date
          <input
            type="date"
            className="tab_css p-2"
            name="to Date"
            value={report_end_date}
            onChange={(e) => setReportEndDate(e.target.value)}
          />
          <button className="btn btn-primary puch_btn" onClick={reload}>
            Reset
          </button>
        </div>
      </div>

      <div className="table-responsive custom-scrollbar table-view-responsive bg-white px-4 py-primary mt-3">
        {!loading ? (
          <table className="table mb-0">
            <thead>
              <tr >
                <th className=" table_color "> S.No.</th>
                <th className=" table_color ">Name</th>
                <th className=" table_color ">Project&nbsp;Name</th>
                <th className=" table_color ">From&nbsp;Time</th>
                <th className=" table_color "> To&nbsp;Time</th>
                <th className=" table_color ">Time&nbsp;Spend</th>
                <th className=" table_color ">Date</th>
                <th className=" table_color w-25"> Work&nbsp;Report</th>
                <th className=" table_color "> Work&nbsp;Status</th>
                <th className=" table_color ">Remark</th>
                <th className=" table_color">Action</th>
              </tr>
            </thead>
           
              <tbody>
                {workList.map((item) => (
                  <tr key={item.work_report_id}>
                    <td>{item.sr_no}</td>
                    <td>{item.user_name}</td>
                    <td>{item.project_name}</td>
                    <td>{item.from_time}</td>
                    <td>{item.to_time}</td>
                    <td>{item.time_spent}</td>
                    <td style={{whiteSpace:"nowrap"}}>{item.work_report_date}</td>
                    <td
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <td>
                      {item.status === "1"
                        ? "Complete"
                        : item.status === "2"
                        ? "Pending"
                        : item.status === "3"
                        ? "Under process"
                        : ""}
                    </td>
                    <td>{item.remark}</td>
                    <td>
                      <div className="d-flex">
                        <span className="hover_btn">
                        </span>
                        &nbsp; &nbsp;
                        <span className="hover_btn">
                          <RiDeleteBin5Line
                            onClick={() => handleAccept(item.work_report_id)}
                            className="left_icon_css text-danger"
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            
           {workList.length > 0 ? 
            <tfoot>
              <th>Total</th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <th>{totalTime}</th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tfoot>
            :
            <tbody>
            <tr className="text-center">
      <td colSpan="12" >
      <div className="pt-5 pb-5">
        <img
          src="/no_data.svg"
          alt="no data"
          width="150px"
        />
        <h6>Nothing to show here</h6>
        <p className="small text-secondary">
          Please add a new entity or manage the data table to see the
          content here <br/>Thank you
        </p>
      </div>
      </td>
          </tr>
      </tbody>
            }
          </table>
        ) : (
          <div className="spinner_height">
            <div className="loader"></div>
          </div>
        )}
      </div>

      {/* ----------------delete popup ----------------*/}
      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to delete this work report</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleAcceptClose}
            >
              No
            </button>
            <button
              className="btn btn_color puch_btn"
              onClick={() => handleDelete(deleted.work_report_id)}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default AllEmployeeReport;
