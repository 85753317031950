import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../utils/Context";
import { Alert, Pagination, Spinner } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { MdCancelPresentation } from "react-icons/md";
import { BsDownload } from "react-icons/bs";

const EmployeeLeaveRequest = ({ menuWidth }) => {

  const { id } = useAuth();
  const [showLeave, setShowLeave] = useState(false);
  const [showEditLeave, setShowEditLeave] = useState(false);
  const [request, setRequest] = useState([]);
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");

  const [loading, setLoading] = useState(false);

  const handleLeave = () => setShowLeave(true);
  const handleCloseleave = () => {
    setShowLeave(false);
    setValidated(false);
    setLoading(false);
    setError(false);
    setLeaveAssign({
      user_id: "",
      leave_type: "",
      leave_duration: "",
      start_date: "",
      last_date: "",
      half_time_duration: "",
      note: "",
    });
  };
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");

  const fetchData1 = useCallback(() => {
    setLoading(true);
    if (id) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/leave_requests?users=${id}&start_date=${start_date}&end_date=${end_date}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setRequest(result.data);
            setLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [id, start_date, end_date])

  useEffect(() => {
    fetchData1();
  }, [start_date, end_date, fetchData1]);

  const [leaveAssign, setLeaveAssign] = useState({
    user_id: "",
    leave_type: "",
    leave_duration: "",
    start_date: "",
    last_date: "",

    half_time_duration: "",
    note: "",
  });
  const [showFields, setShowFields] = useState({
    startDate: false,
    endDate: false,
    firstHalf: false,
    secondHalf: false,
    startTime: false,
    endTime: false,
  });
  const [showFields1, setShowFields1] = useState({
    startDate: false,
    endDate: false,
    firstHalf: false,
    secondHalf: false,
    startTime: false,
    endTime: false,
  });

  const [accept, setAccept] = useState(false);
  const handleAccept = (leave_id) => {
    setCancel({ leave_id });
    setAccept(true);
  };

  const handleAcceptClose = () => setAccept(false);

  const [cancel, setCancel] = useState({
    leave_id: "",
    leave_status: "3"
  });

  const HandleCancelLeave = () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("leave_id", cancel.leave_id);
    formdata.append("leave_status", "3");

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/leave_cancel`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setCancel(!cancel)
          fetchData1();
          handleAcceptClose();
          setLoading(false);
        } else {
          setError(result.message);
          setLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleLeaveAssign = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    setLoading(true);
    var formdata = new FormData();
    formdata.append("user_id", id);
    formdata.append("leave_type", leaveAssign.leave_type);
    formdata.append("leave_duration", leaveAssign.leave_duration);
    formdata.append("start_date", leaveAssign.start_date);
    formdata.append("last_date", leaveAssign.last_date);
    formdata.append("half_time_duration", leaveAssign.half_time_duration);
    formdata.append("note", leaveAssign.note);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/assign_leave_request`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleCloseleave();
          fetchData1();

          setLoading(false);
        } else {
          setError(result.message);
          setLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };


  const handleEditLeave = () => setShowEditLeave(true);
  const handleCloseEditleave = () => {
    setShowEditLeave(false);
    setValidated(false);
    setLoading(false);
    setError(false);
  };





  const [isSingleDay, setIsSingleDay] = useState(false);
  const [isMultiDay, setIsMultiDay] = useState(false);
  const [isHalfDay, setIsHalfDay] = useState(false);
  const [isFirstHalf, setIsFirstHalf] = useState(false);
  const [isSecondHalf, setIsSecondHalf] = useState(false);
  useEffect(() => {
    setUpdateLeave((prev) => ({
      ...prev,
      leave_duration: "Single Day",
    }));
    setShowFields1({
      startDate: true,
      endDate: false,
      firstHalf: false,
      secondHalf: false,
      startTime: false,
      endTime: false,
    });
  }, []);

  const handleLeaveDurationChange = (e) => {
    const { name, checked } = e.target;

    // Update checkbox states and input visibility
    if (name === "singleDay") {
      setIsSingleDay(checked);
      setIsMultiDay(false);
      setIsHalfDay(false);
      setShowFields1({
        startDate: true,
        endDate: false,
        firstHalf: false,
        secondHalf: false,
        startTime: false,
        endTime: false,
      });
      setUpdateLeave({ ...updateLeave, leave_duration: "Single Day" });
    } else if (name === "multiDay") {
      setIsSingleDay(false);
      setIsMultiDay(checked);
      setIsHalfDay(false);
      setShowFields1({
        startDate: true,
        endDate: true,
        firstHalf: false,
        secondHalf: false,
        startTime: false,
        endTime: false,
      });
      setUpdateLeave({ ...updateLeave, leave_duration: "Multi Day" });
    } else if (name === "halfDay") {
      setIsSingleDay(false);
      setIsMultiDay(false);
      setIsHalfDay(checked);
      setShowFields1({
        startDate: true,
        endDate: false,
        firstHalf: true,
        secondHalf: true,
        startTime: false,
        endTime: false,
      });
      setUpdateLeave({ ...updateLeave, leave_duration: "Half Day" });
    }
  };


  const [updateLeave, setUpdateLeave] = useState({
    user_id: "",
    leave_type: "",
    leave_duration: "",
    start_date: "",
    start_time: "",
    last_date: "",
    attachment: "",
    half_time_duration: "",
    note: "",
  });

  const [leaveId, setLeaveId] = useState();

  const fetchData = useCallback(() => {
    if (leaveId) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/get_leave_detail?leave_id=${leaveId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setUpdateLeave(result.data);

            if (result.data.leave_duration === "Single Day") {
              setIsSingleDay(true);
              setIsMultiDay(false);
              setIsHalfDay(false);
              setShowFields1({
                startDate: true,
                endDate: false,
                firstHalf: false,
                secondHalf: false,
              });
            } else if (result.data.leave_duration === "Multi Day") {
              setIsSingleDay(false);
              setIsMultiDay(true);
              setIsHalfDay(false);
              setShowFields1({
                startDate: true,
                endDate: true,
                firstHalf: false,
                secondHalf: false,
              });
            } else if (result.data.leave_duration === "Half Day") {
              setIsSingleDay(false);
              setIsMultiDay(false);
              setIsHalfDay(true);
              setShowFields1({
                startDate: true,
                endDate: false,
                firstHalf: true,
                secondHalf: true,
              });
            }
            if (result.data.half_time_duration === "First Half") {
              setIsFirstHalf(true);
              setIsSecondHalf(false);
            } else if (result.data.half_time_duration === "Second Half") {
              setIsFirstHalf(false);
              setIsSecondHalf(true);
            } else {
              setIsFirstHalf(false);
              setIsSecondHalf(false);
            }
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [leaveId]);

useEffect(() => {
  fetchData();
}, [leaveId]);

  const handleEditleave = () => {
    var formdata = new FormData();
    formdata.append("user_id", id);
    formdata.append("leave_id", leaveId);
    formdata.append("leave_type", updateLeave.leave_type);
    formdata.append("leave_duration", isSingleDay ? "Single Day" : isMultiDay ? "Multi Day" : "Half Day");
    formdata.append("start_date", updateLeave.start_date);
    formdata.append("last_date", updateLeave.last_date);
    formdata.append("half_time_duration", updateLeave.half_time_duration);
    formdata.append("note", updateLeave.note);

     // Append project_link only if it's not null or empty
     if (updateLeave.note && updateLeave.note.trim() !== "") {
      formdata.append("note", updateLeave.note.trim());
    } else {
      // If project_link is null or empty, append it with an empty string
      formdata.append("note", "");
    }


    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/leave-detail-update`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          fetchData1();
          handleCloseEditleave();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleEditLeaaveopen = (leaveId) => {
    setLeaveId(leaveId);
    handleEditLeave();
  };



  const formatDate = (dateString) => {
    if (!dateString) return "Not added yet";
    // Parse the date string into a Date object
    const date = new Date(dateString);
    // Extract day, month, and year
    const day = date.getDate();
    const month = [date.getMonth()];
    const year = date.getFullYear();
    // Format the date as desired
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  };


  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 8;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(request.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = request.slice(indexOfFirstProject, indexOfLastProject);



  // const currentDate = new Date().toISOString().split('T')[0];
  // const isLeavePastOrToday = new Date(start_date) <= new Date(currentDate);

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4 className="m-0">Leave Request</h4>
        <div>
          <button className="btn btn-primary puch_btn" onClick={handleLeave}>
            Apply leave
          </button>
        </div>
      </div>
      <div className="employee_table p-4 text-center">
        <div className="d-lg-flex justify-content-between align-items-center">
          From
          <Form.Control
            type="date"
            className="mx-2 h-75"
            value={start_date}
            onChange={(e) => setStartDate(e.target.value)}
          />
          To
          <Form.Control
            type="date"
            className="mx-2 h-75"
            value={end_date}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div>

        {!loading ? (
          <div className="table-responsive custom-scrollbar table-view-responsive  py-primary">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th className="py-4 px-3 table_color ">Profile</th>
                  <th className="py-4 px-3 table_color ">Date & Time</th>
                  <th className="py-4 px-3 table_color ">Leave Duration</th>
                  <th className="py-4 px-3 table_color ">Leave Type</th>
                  <th className="py-4 px-3 table_color ">Note</th>
                  <th className="py-4 px-3 table_color ">Status</th>
                  <th className="py-4 px-3 table_color">Apply Date </th>
                  <th className="py-4 px-3 table_color">Action</th>
                </tr>
              </thead>
              {currentProjects.length > 0 ? (
                <tbody>
                  {currentProjects &&
                    currentProjects.map((item) => (
                      <tr key={item.leave_id}>
                        <td>{item.employee_name}</td>
                        <td>
                          {item.leave_duration === "Multi Day" ? "From" : ""}{" "}
                          {item.leave_start_date}{" "}
                          {item.leave_duration === "Multi Day" ? (
                            <>to {item.leave_end_date}</>
                          ) : (
                            ""
                          )}
                          {/* {item.leave_duration === "Half Day" ? (
                            <>{item.half_time_duration}</>
                          ) : (
                            ""
                          )}
                          {item.leave_duration === "Hours" ? (
                            <>
                              {formatDate(item.leave_start_time)} to{" "}
                              {formatDate(item.leave_end_time)}
                            </>
                          ) : (
                            ""
                          )} */}
                        </td>
                        <td>{item.leave_duration}</td>
                        <td>{item.leave_type}</td>
                        <td>{item.note}</td>
                        <td>
                          <span
                            className={`rounded-pill py-1 px-2 text-white p_css ${item.status === "Pending"
                              ? "bg-warning"
                              : item.status === "Approved"
                                ? "bg-success"
                                : item.status === "Rejected"
                                  ? "bg-danger"
                                  : item.status === "Cancelled"
                                    ? "bg-danger"
                                    : " "
                              }`}
                          >
                            {item.status}
                          </span>
                        </td>
                        <td>{item.created_at}</td>
                        {/* {isLeavePastOrToday && ( */}
                        <td>
                
                              {item.status === "Pending"? (
                            <div>
                              <FaEdit className="left_icon_css" title="Edit Leave"
                                onClick={() => {
                                  handleEditLeaaveopen(item.leave_id)
                                }} />
                              &nbsp;
                              <MdCancelPresentation className="left_icon_css text-danger" title="Cancel Leave"
                                onClick={() => {
                                  handleAccept(item.leave_id)
                                }}
                              />
                            </div>)
                            : ""
 }
                        </td>
                         {/* )} */}
                      </tr>
                    ))}
                </tbody>
              ) : (
                <tbody className="pt-5 pb-5">
                  <tr>
                    <td colSpan="8">
                      <img src="/no_data.svg" alt="no data" width="150px" />
                      <h6>Nothing to show here</h6>
                      <p>
                        Please add a new entity or manage the data table to see
                        the content here Thank you
                      </p>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
            <Pagination className="mt-2 float-right">
              <Pagination.First onClick={() => handlePageChange(1)} />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {[...Array(totalPages)].map((_, index) => {
                // Display only a fixed number of page numbers before and after the current page
                if (
                  index + 1 === 1 || // Display the first page
                  index + 1 === totalPages || // Display the last page
                  index + 1 === currentPage || // Display the current page
                  index + 1 === currentPage - 1 || // Display the page before the current page
                  index + 1 === currentPage + 1 // Display the page after the current page
                ) {
                  return (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  );
                } else if (
                  index + 1 === currentPage - 2 || // Display ellipsis two pages before the current page
                  index + 1 === currentPage + 2 // Display ellipsis two pages after the current page
                ) {
                  return <Pagination.Ellipsis key={index + 1} />;
                }
                return null;
              })}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last onClick={() => handlePageChange(totalPages)} />
            </Pagination>
          </div>
      ) : (
        <div className="spinner_height">
          <div className="loader"></div>
        </div>
      )}
      </div>
      </div>
      {/*--------------Assign Leave----------------*/}
      <Modal show={showLeave} onHide={handleCloseleave} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Request leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleLeaveAssign}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Leave Type</Form.Label>
              <Form.Control
                type="text"
                className="w-100"
                onChange={(e) => {
                  setLeaveAssign((prevState) => ({
                    ...prevState,
                    leave_type: e.target.value,
                  }));
                }}
                required
              />
            </Form.Group>

            <Form.Group className="mt-3 d-lg-flex justify-content-between">
              <Form.Label className="w-50">Age (Leave duration)</Form.Label>
              <Form.Group
                className="mb-3 d-lg-flex  justify-content-between w-100"
                controlId="leaveDuration"
              >
                <Form.Check
                  type="radio"
                  label="Single Day"
                  name="leaveDuration"
                  value="Single Day"
                  onChange={(e) => {
                    setLeaveAssign({
                      ...leaveAssign,
                      leave_duration: e.target.value,
                    });
                    setShowFields({
                      startDate: true,
                      endDate: false,
                      firstHalf: false,
                      secondHalf: false,
                      startTime: false,
                      endTime: false,
                    });
                  }}
                  required
                />
                <Form.Check
                  type="radio"
                  label="Multi Day"
                  name="leaveDuration"
                  value="Multi Day"
                  onChange={(e) => {
                    setLeaveAssign({
                      ...leaveAssign,
                      leave_duration: e.target.value,
                    });
                    setShowFields({
                      startDate: true,
                      endDate: true,
                      firstHalf: false,
                      secondHalf: false,
                      startTime: false,
                      endTime: false,
                    });
                  }}
                  required
                />
                <Form.Check
                  type="radio"
                  label="Half Day"
                  name="leaveDuration"
                  value="Half Day"
                  onChange={(e) => {
                    setLeaveAssign({
                      ...leaveAssign,
                      leave_duration: e.target.value,
                    });
                    setShowFields({
                      startDate: true,
                      endDate: false,
                      firstHalf: true,
                      secondHalf: true,
                      startTime: false,
                      endTime: false,
                    });
                  }}
                />
                {/* <Form.Check
                  type="radio"
                  label="Hours"
                  name="leaveDuration"
                  value="Hours"
                  onChange={(e) => {
                    setLeaveAssign({
                      ...leaveAssign,
                      leave_duration: e.target.value,
                    });
                    setShowFields({
                      startDate: true,
                      endDate: false,
                      firstHalf: false,
                      secondHalf: false,
                      startTime: true,
                      endTime: true,
                    });
                  }}
                /> */}
              </Form.Group>
            </Form.Group>

            <div className="d-lg-flex justify-content-between align-items-center">
              {showFields.startDate && (
                <Form.Group className="mb-3 w-100" controlId="startDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    className="w-100"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        start_date: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields.endDate && (
                <Form.Group className="mb-3 w-100" controlId="endDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    className="w-100"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        last_date: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields.firstHalf && (
                <Form.Group className=" w-25" controlId="firstHalf">
                  <Form.Label></Form.Label>
                  <Form.Check
                    type="radio"
                    label="First Half"
                    name="first"
                    value="First Half"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        half_time_duration: "First Half",
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields.secondHalf && (
                <Form.Group className="w-25" controlId="firstHalf">
                  <Form.Label></Form.Label>
                  <Form.Check
                    type="radio"
                    label="Second Half"
                    name="first"
                    value="Second Half"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        half_time_duration: "Second Half",
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields.startTime && (
                <Form.Group className="mb-3 w-100" controlId="startTime">
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    type="time"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        start_time: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields.endTime && (
                <Form.Group className="mb-3 w-100" controlId="endTime">
                  <Form.Label>End Time</Form.Label>
                  <Form.Control
                    type="time"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        end_time: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
            </div>
            <div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Reason note</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter note"
                  rows={3}
                  onChange={(e) =>
                    setLeaveAssign({ ...leaveAssign, note: e.target.value })
                  }
                />
              </Form.Group>
              {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Attachments</Form.Label>
                  <Form.Control type="file"
                   placeholder="Upload File" 
                   value={leaveAssign.attachment}
                   onChange={(e) => setLeaveAssign({...leaveAssign, attachment:e.target.value})}/>
                </Form.Group>  */}
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleCloseleave}
                type="button"
              >
                Cancel
              </button>

              {loading ? (
                <button className="btn btn-primary puch_btn" type="button">
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                  &nbsp;
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                </button>
              ) : (
                <button className="btn btn-primary puch_btn" type="submit">
                  Save
                </button>
              )}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/*--------------Edit Leave----------------*/}
      <Modal show={showEditLeave} onHide={handleCloseEditleave} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Leave Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Leave Type</Form.Label>
              <Form.Control
                type="text"
                className="w-100"
                value={updateLeave.leave_type}
                onChange={(e) => {
                  setUpdateLeave((prevState) => ({
                    ...prevState,
                    leave_type: e.target.value,
                  }));
                }}
                required
              />
            </Form.Group>

            <Form.Group className="mt-3 d-lg-flex justify-content-between">
              <Form.Label className="w-50">Age (Leave duration)</Form.Label>
              <div class="mb-3 d-lg-flex  justify-content-between w-100">
                <Form.Check
                  type="radio"
                  label="Single Day"
                  name="singleDay"
                  checked={isSingleDay}
                  onChange={handleLeaveDurationChange}
                />
                <Form.Check
                  type="radio"
                  label="Multi Day"
                  name="multiDay"
                  checked={isMultiDay}
                  onChange={handleLeaveDurationChange}
                />
                <Form.Check
                  type="radio"
                  label="Half Day"
                  name="halfDay"
                  checked={isHalfDay}
                  onChange={handleLeaveDurationChange}
                />
              </div>
            </Form.Group>
            <div class="d-lg-flex  justify-content-between align-items-end">
              {showFields1.startDate && (
                <Form.Group class="w-100">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={updateLeave.start_date}
                    onChange={(e) => setUpdateLeave({ ...updateLeave, start_date: e.target.value })}
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields1.endDate && (
                <Form.Group class="w-100">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={updateLeave.last_date}
                    onChange={(e) => setUpdateLeave({ ...updateLeave, last_date: e.target.value })}
                  />
                </Form.Group>
              )}

              {showFields1.firstHalf && (
                <Form.Group class="w-50">
                  <Form.Check
                    type="radio"
                    label="First Half"
                    name="firstHalf"
                    checked={isFirstHalf}
                    onChange={(e) => {
                      setIsFirstHalf(e.target.checked);
                      setUpdateLeave({
                        ...updateLeave,
                        half_time_duration: e.target.checked ? 'First Half' : isSecondHalf ? 'Second Half' : ''
                      });
                    }}
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields1.secondHalf && (
                <Form.Group class="w-50">
                  <Form.Check
                    type="radio"
                    label="Second Half"
                    name="firstHalf"
                    checked={isSecondHalf}
                    onChange={(e) => {
                      setIsSecondHalf(e.target.checked);
                      setUpdateLeave({
                        ...updateLeave,
                        half_time_duration: e.target.checked ? 'Second Half' : isFirstHalf ? 'First Half' : ''
                      });
                    }}
                  />
                </Form.Group>
              )}
            </div>
            <div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Reason note</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter note"
                  rows={3}
                  value={updateLeave.note}
                  onChange={(e) =>
                    setUpdateLeave({ ...updateLeave, note: e.target.value || "" })
                  }
                />
              </Form.Group>
              {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Attachments</Form.Label>
                  <Form.Control type="file"
                   placeholder="Upload File" 
                   value={leaveAssign.attachment}
                   onChange={(e) => setLeaveAssign({...leaveAssign, attachment:e.target.value})}/>
                </Form.Group>  */}
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleCloseEditleave}
                type="button"
              >
                Cancel
              </button>

              {loading ? (
                <button className="btn btn-primary puch_btn" type="button">
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                  &nbsp;
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                </button>
              ) : (
                <button className="btn btn-primary puch_btn" onClick={handleEditleave}>
                  Save
                </button>
              )}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* ----------------cancel popup ----------------*/}
      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to cancel this leave</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleAcceptClose}
            >
              No
            </button>
            {loading ? (
                <button className="btn btn_color puch_btn" type="button">
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                  &nbsp;
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                </button>
              ) : (
            <button
              className="btn btn_color puch_btn"
              onClick={() => HandleCancelLeave()}
            >
              Yes
            </button>
)}
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default EmployeeLeaveRequest;