import React, { useEffect, useState } from "react";
import {
  FiBriefcase,
  FiCalendar,
  FiCreditCard,
  FiPieChart,
  FiUser,
  FiUsers,
} from "react-icons/fi";
import { PiCalendarCheckDuotone } from "react-icons/pi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { MdOutlineWatchLater } from "react-icons/md";
import { Dropdown, Accordion } from "react-bootstrap";
import { AiOutlineAlignLeft } from "react-icons/ai";
import { FaUser } from "react-icons/fa6";
import { FaProjectDiagram } from "react-icons/fa";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LiaClipboardListSolid } from "react-icons/lia";
import { RxDashboard } from "react-icons/rx";
import { LuLogOut } from "react-icons/lu";
import { useAuth } from "../utils/Context";
import { handleLogout } from "../utils/AuthFunctions";

const TIMEOUT = 3 * 60 * 60 * 1000; 
const LeftMenu = ({ menuWidth, toggleMenuWidth }) => {
  const {
    setLoggedIn,
    setIsLoggedIn,
    first_name,
    role_id,
    role_name,
    last_name,
    id,
  } = useAuth();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     localStorage.clear();
  //     setIsLoggedIn(false);
  //     navigate("/");
  //   }, 3 * 60 * 60 * 1000);

  //   return () => clearTimeout(timeout);
  // }, [navigate, setIsLoggedIn]);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Logout the user after TIMEOUT
      setIsLoggedIn(false);
    }, TIMEOUT);

    // Clear the timeout on component unmount or when user logs out
    return () => clearTimeout(timeoutId);
    
  }, [setIsLoggedIn]);
  
  const logoutUser = handleLogout(setIsLoggedIn);

  // const handleLogout = () => {
  //   setLoggedIn(false);
  //   localStorage.clear();
  //   window.location.href = "/";
  // };

  const [addCredentials, setAddCredentials] = useState({});

  useEffect(() => {
    if (role_id) {
      const fetchData = () => {
        var formdata = new FormData();
        formdata.append("employee_id", id);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/admin/profile`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true) {
              setAddCredentials(result.data);

              document.cookie = `photo=${result.data.photo}`;
            }
          })
          .catch((error) => console.log("error", error));
      };
      fetchData();
    }
  }, [role_id, id]);

  return (
    <section>
      <div className="web_display">
        <div className={`wrapper ${menuWidth === 50 ? "menu-collapsed" : ""}`}>
          <div className="d-flex justify-content-between align-items-center nav_div">
            <div className="d-flex" style={{ marginLeft: `${menuWidth}px` }}>
              <AiOutlineAlignLeft
                className="left_icon_css"
                onClick={toggleMenuWidth}
              />
            </div>
            <div className="d-flex">
              <div className="px-2">
                <h6 className="m-0 text-end">
                  {first_name} {last_name}
                </h6>
                <p className="m-0">{role_name}</p>
              </div>
              <Dropdown data-bs-theme="white">
                <Dropdown.Toggle
                  id="dropdown-button-dark-example1"
                  variant="white"
                  style={{
                    background: "transparent",
                    border: "none",
                    padding: "0",
                    boxShadow: "none",
                  }}
                 >
                  {addCredentials.photo ? (
                    <img
                      src={addCredentials.photo}
                      alt="profile"
                      className="navbar_profile"
                    />
                  ) : (
                    <img
                      src="/avtaar.jpg"
                      alt="profile"
                      className="navbar_profile"
                    />
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-3" style={{ zIndex: "99999" }}>
                  <div className="d-flex align-items-center">
                    {addCredentials.photo ? (
                      <img
                        src={addCredentials.photo}
                        alt="profile"
                        className="navbar_profile"
                      />
                    ) : (
                      <img
                        src="/avtaar.jpg"
                        alt="profile"
                        className="navbar_profile"
                      />
                    )}
                    <div className="px-2">
                      <h6 className="m-0">
                        {first_name}&nbsp;{last_name}
                      </h6>
                    </div>
                  </div>
                  <Link
                    to="/my-profile"
                    className="profile_links text-decoration-none d-flex  align-items-center"
                  >
                    <FiUser /> <p className="mb-0 mx-2">My Profile</p>
                  </Link>
                  <Link
                    onClick={logoutUser}
                    className="profile_links text-decoration-none d-flex  align-items-center"
                  >
                    <LuLogOut /> <p className="mb-0 mx-2">Logout</p>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="sidebar_div" style={{ width: `${menuWidth}px` }}>
            <div className="row h-100">
              <div
                style={{ background: "#000", height: "60px" }}
                className="d-flex justify-cintent-center aligin-items-center"
              >
                <img
                  src="/softgen-log-1.png"
                  width="60%"
                  alt="logo"
                  className="d-flex m-auto"
                />
              </div>
              <div className="navbar_left px-4 py-3">
                <div className=" mb-5">
                  <NavLink
                    to="/"
                    className="d-flex align-items-center text-decoration-none mt-1 mb-2 curser_css sidebar_text_css"
                  >
                    <div className="sidebar_icons">
                      <FiPieChart className="sidebar_icons_width" />
                    </div>
                    <div className="my-0 mx-3">Dashboard</div>
                  </NavLink>
                  {role_id === "2" && (
                    <NavLink
                      to="/jobdisk"
                      className=" d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                    >
                      <div className="sidebar_icons">
                        <FiUser className="sidebar_icons_width" />
                      </div>
                      <div
                        to="/jobdisk"
                        className="my-0 mx-3 text-decoration-none"
                      >
                        Job Desk
                      </div>
                    </NavLink>
                  )}
                  <NavLink
                    to="/projectdetails"
                    className="d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                  >
                    <div className="sidebar_icons">
                      <FaProjectDiagram className="sidebar_icons_width" />
                    </div>
                    <div className="my-0 mx-3 ">Project Details</div>
                  </NavLink>
                  {/* <NavLink
                    to="/projectmanagement"
                    className="d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                  >
                    <div className="sidebar_icons">
                      <RxDashboard className="sidebar_icons_width" />
                    </div>
                    <div className="my-0 mx-3 ">Project Management</div>
                  </NavLink> */}

                  {role_id === "2" && (
                    <NavLink
                      to="/dailyworkreport "
                      className="d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                    >
                      <div className="sidebar_icons">
                        <LiaClipboardListSolid className="sidebar_icons_width" />
                      </div>
                      <div className="my-0 mx-3 text-decoration-none">
                        Daily Work Report
                      </div>
                    </NavLink>
                  )}
                  {role_id === "1" && (
                    <NavLink
                      to="/employeedailyworkreport "
                      className="d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                    >
                      <div className="sidebar_icons">
                        <LiaClipboardListSolid className="sidebar_icons_width" />
                      </div>
                      <div className="my-0 mx-3 text-decoration-none">
                        Daily Work Report
                      </div>
                    </NavLink>
                  )}

                  {role_id === "2" && (
                    <>
                      <NavLink
                        to="/holiday"
                        className="d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                      >
                        <div className="sidebar_icons">
                          <PiCalendarCheckDuotone className="sidebar_icons_width" />
                        </div>
                        <div className="my-0 mx-3 text-decoration-none ">
                          Holiday
                        </div>
                      </NavLink>
                
                    

                      <Accordion>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header className="sidebar_text_css">
                            <div className="sidebar_icons icon2">
                              <MdOutlineWatchLater className="sidebar_icons_width" />
                            </div>
                            <div
                              className="sidebar_text_width my-0"
                              style={{ marginLeft: "20px" }}
                            >
                              Leave
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <ul>
                              {/* <li className="employee_css">
                                <NavLink
                                  to="/leave/employeestatus"
                                  className=" text-decoration-none text-white"
                                >
                                  Leave Status
                                </NavLink>
                              </li> */}
                              <li className="employee_css">
                                <NavLink
                                  to="/leave/employeerequest/"
                                  className=" text-decoration-none text-white"
                                >
                                  Leave Request
                                </NavLink>
                              </li>
                              <li className="employee_css">
                                <NavLink
                                  to="/movement"
                                  className=" text-decoration-none text-white"
                                >
                                  Movement
                                </NavLink>
                              </li>
                              {/* <li className="employee_css">
                                <NavLink
                                  to="/leave/employeesummary"
                                  className=" text-decoration-none text-white"
                                >
                                  Summary
                                </NavLink>
                              </li> */}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <div className="sidebar_icons icon2">
                              <FiCalendar className="sidebar_icons_width" />
                            </div>
                            <div
                              className="sidebar_text_width my-0"
                              style={{ marginLeft: "20px" }}
                            >
                              Attendance
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <ul>
                              <li className="employee_css">
                                <NavLink
                                  to="/attendance/dailylog"
                                  className=" text-decoration-none text-white"
                                >
                                  Daily Log
                                </NavLink>
                              </li>
                              <li className="employee_css">
                                <NavLink
                                  to="/attendance/details"
                                  className=" text-decoration-none text-white"
                                >
                                  Attendance Details
                                </NavLink>
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  )}

                  {role_id === "1" ? (
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="sidebar_icons icon2">
                            <FiUsers className="sidebar_icons_width" />
                          </div>
                          <div
                            className="sidebar_text_width my-0"
                            style={{ marginLeft: "20px" }}
                          >
                            Employee
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <ul>
                            <li className="employee_css">
                              <NavLink
                                to="/employee/list"
                                className=" text-decoration-none text-white"
                              >
                                All Employees
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/employee/designation"
                                className=" text-decoration-none text-white"
                              >
                                Designation
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/employee/employmentstatus"
                                className=" text-decoration-none text-white"
                              >
                                Employment Status
                              </NavLink>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <div className="sidebar_icons icon2">
                            <MdOutlineWatchLater className="sidebar_icons_width" />
                          </div>
                          <div
                            className="sidebar_text_width my-0"
                            style={{ marginLeft: "20px" }}
                          >
                            Leave
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <ul>
                            {/* <li className="employee_css">
                              <NavLink
                                to="/leave/status"
                                className=" text-decoration-none text-white"
                              >
                                Leave Status
                              </NavLink>
                            </li> */}
                            <li className="employee_css">
                              <NavLink
                                to="/leave/request"
                                className=" text-decoration-none text-white"
                              >
                                Leave Request
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/movement"
                                className=" text-decoration-none text-white"
                              >
                                Movement
                              </NavLink>
                            </li>
                            {/* <li className="employee_css">
                              <NavLink
                                to="/leave/summary"
                                className=" text-decoration-none text-white"
                              >
                                Summary
                              </NavLink>
                            </li> */}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <div className="sidebar_icons icon2">
                            <FiCalendar className="sidebar_icons_width" />
                          </div>
                          <div
                            className="sidebar_text_width my-0"
                            style={{ marginLeft: "20px" }}
                          >
                            Attendance
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <ul>
                            <li className="employee_css">
                              <NavLink
                                to="/attendance/dailylog"
                                className=" text-decoration-none text-white"
                              >
                                Daily Log
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/attendance/details"
                                className=" text-decoration-none text-white"
                              >
                                Attendance Details
                              </NavLink>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          <div className="sidebar_icons icon2">
                            <FiCreditCard className="sidebar_icons_width" />
                          </div>
                          <div
                            className="sidebar_text_width my-0"
                            style={{ marginLeft: "20px" }}
                          >
                            {" "}
                            Payroll
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <ul>
                            <li className="employee_css">
                              <NavLink
                                to="payroll/payslip"
                                className=" text-decoration-none text-white"
                              >
                                Payslip
                              </NavLink>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          <div className="sidebar_icons icon2">
                            <FiBriefcase className="sidebar_icons_width" />
                          </div>
                          <div
                            className="sidebar_text_width my-0"
                            style={{ marginLeft: "20px" }}
                          >
                            {" "}
                            Administration
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <ul>
                            <li className="employee_css">
                              <NavLink
                                to="/administration/departments"
                                className=" text-decoration-none text-white"
                              >
                                Departments
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/administration/holiday"
                                className=" text-decoration-none text-white"
                              >
                                Holiday
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/administration/announcements"
                                className=" text-decoration-none text-white"
                              >
                                Announcements
                              </NavLink>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    
                    </Accordion>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="web_view p-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <AiOutlineAlignLeft
              onClick={handleShow}
              className="left_icon_css "
            />
          </div>
          <div className="d-flex">
            <div className="px-2">
              <h6 className="m-0 text-end">
                {first_name} {last_name}
              </h6>
              <p className="m-0">{role_name}</p>
            </div>

            <Dropdown data-bs-theme="white">
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="white"
                style={{
                  background: "transparent",
                  border: "none",
                  padding: "0",
                  boxShadow: "none",
                }}
              >
                {addCredentials.photo ? (
                  <img
                    src={addCredentials.photo}
                    alt="profile"
                    className="navbar_profile"
                  />
                ) : (
                  <img
                    src="/avtaar.jpg"
                    alt="profile"
                    className="navbar_profile m-2"
                  />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu className="p-3">
                <div className="d-flex">
                  {addCredentials.photo ? (
                    <img
                      src={addCredentials.photo}
                      alt="profile"
                      className="navbar_profile"
                    />
                  ) : (
                    <img
                      src="/avtaar.jpg"
                      alt="profile"
                      className="navbar_profile"
                    />
                  )}
                  <div className="px-2">
                    <h6 className="m-0">
                      {first_name} {last_name}
                    </h6>
                  </div>
                </div>

                <Link
                  to="/my-profile"
                  className="profile_links text-decoration-none d-flex  align-items-center"
                >
                  <FaUser /> <p className="mb-0 mx-2">My Profile</p>
                </Link>
                <Link
                  onClick={logoutUser}
                  className="profile_links text-decoration-none d-flex  align-items-center"
                >
                  <LuLogOut /> <p className="mb-0 mx-2">Logout</p>
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="d-lg-none d-md-none d-sm-block">
        <Offcanvas
          show={show}
          onHide={handleClose}
          className=" d-lg-none d-sm-block"
          style={{ width: "230px", background: "#000" }}
        >
          <Offcanvas.Header closeButton className="my-0">
            <Offcanvas.Title>
              <img
                src="/softgen-log-1.png"
                width="60%"
                alt="logo"
                className="d-flex m-auto"
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row h-100">
              <div className="navbar_left px-4 py-3">
                <div>
                  <Link
                    to="/"
                    className="d-flex align-items-center text-decoration-none mt-1 mb-2 curser_css sidebar_text_css"
                    onClick={handleClose}
                  >
                    <div className="sidebar_icons">
                      <FiPieChart className="sidebar_icons_width" />
                    </div>
                    <div className="my-0 mx-3 ">Dashboard</div>
                  </Link>
                  <Link
                    to="/jobdisk"
                    className=" d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                    onClick={handleClose}
                  >
                    <div className="sidebar_icons">
                      <FiUser className="sidebar_icons_width" />
                    </div>
                    <div className="my-0 mx-3 text-decoration-none">
                      Job Desk
                    </div>
                  </Link>
                  <Link
                    to="/projectdetails"
                    className="d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                    onClick={handleClose}
                  >
                    <div className="sidebar_icons">
                      <FaProjectDiagram className="sidebar_icons_width" />
                    </div>
                    <div className="my-0 mx-3 ">Project Details</div>
                  </Link>
                  {role_id === "2" && (
                    <Link
                      to="/dailyworkreport "
                      className="d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                      onClick={handleClose}
                    >
                      <div className="sidebar_icons">
                        <LiaClipboardListSolid className="sidebar_icons_width" />
                      </div>
                      <div className="my-0 mx-3 text-decoration-none">
                        Daily Work Report
                      </div>
                    </Link>
                  )}

                  {role_id === "1" && (
                    <Link
                      to="/employeedailyworkreport "
                      className="d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                      onClick={handleClose}
                    >
                      <div className="sidebar_icons">
                        <LiaClipboardListSolid className="sidebar_icons_width" />
                      </div>
                      <div className="my-0 mx-3 text-decoration-none">
                        Daily Work Report
                      </div>
                    </Link>
                  )}
                  {role_id === "2" && (
                    <>
                      <Link
                        to="/holiday"
                        className="d-flex align-items-center text-decoration-none mt-3 mb-2 curser_css sidebar_text_css"
                        onClick={handleClose}
                       >
                        <div className="sidebar_icons">
                          <PiCalendarCheckDuotone  className="sidebar_icons_width" />
                        </div>
                        <div className="my-0 mx-3 text-decoration-none ">
                          Holiday
                        </div>
                      </Link>
                      <Accordion>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header className="sidebar_text_css">
                            <div className="sidebar_icons icon2">
                              <MdOutlineWatchLater className="sidebar_icons_width" />
                            </div>
                            <div
                              className="sidebar_text_width my-0"
                              style={{ marginLeft: "20px" }}
                            >
                              Leave
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <ul>
                              {/* <li className="employee_css">
                                <NavLink
                                  to="/leave/employeestatus"
                                  className=" text-decoration-none text-white"
                                >
                                  Leave Status
                                </NavLink>
                              </li> */}
                              <li className="employee_css">
                                <NavLink
                                  to="/leave/employeerequest/"
                                  className=" text-decoration-none text-white"
                                >
                                  Leave Request
                                </NavLink>
                              </li>
                              <li className="employee_css">
                                <NavLink
                                  to="/movement"
                                  className=" text-decoration-none text-white"
                                >
                                  Movement
                                </NavLink>
                              </li>
                              {/* <li className="employee_css">
                                <NavLink
                                  to="/leave/employeesummary"
                                  className=" text-decoration-none text-white"
                                >
                                  Summary
                                </NavLink>
                              </li> */}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <div className="sidebar_icons icon2">
                              <FiCalendar className="sidebar_icons_width" />
                            </div>
                            <div
                              className="sidebar_text_width my-0"
                              style={{ marginLeft: "20px" }}
                            >
                              Attendance
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <ul>
                              <li className="employee_css">
                                <NavLink
                                  to="/attendance/dailylog"
                                  className=" text-decoration-none text-white"
                                >
                                  Daily Log
                                </NavLink>
                              </li>
                              <li className="employee_css">
                                <NavLink
                                  to="/attendance/details"
                                  className=" text-decoration-none text-white"
                                >
                                  Attendance Details
                                </NavLink>
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  )}
                  {role_id === "1" ? (
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="sidebar_icons icon2">
                            <FiUsers className="sidebar_icons_width" />
                          </div>
                          <div
                            className="sidebar_text_width my-0"
                            style={{ marginLeft: "20px" }}
                          >
                            Employee
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <ul>
                            <li className="employee_css">
                              <NavLink
                                to="/employee/list"
                                className=" text-decoration-none text-white"
                              >
                                All Employees
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/employee/designation"
                                className=" text-decoration-none text-white"
                              >
                                Designation
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/employee/employmentstatus"
                                className=" text-decoration-none text-white"
                              >
                                Employment Status
                              </NavLink>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <div className="sidebar_icons icon2">
                            <MdOutlineWatchLater className="sidebar_icons_width" />
                          </div>
                          <div
                            className="sidebar_text_width my-0"
                            style={{ marginLeft: "20px" }}
                          >
                            Leave
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <ul>
                            {/* <li className="employee_css">
                              <NavLink
                                to="/leave/status"
                                className=" text-decoration-none text-white"
                              >
                                Leave Status
                              </NavLink>
                            </li> */}
                            <li className="employee_css">
                              <NavLink
                                to="/leave/request"
                                className=" text-decoration-none text-white"
                              >
                                Leave Request
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/movement"
                                className=" text-decoration-none text-white"
                              >
                                Movement
                              </NavLink>
                            </li>
                            {/* <li className="employee_css">
                              <NavLink
                                to="/leave/summary"
                                className=" text-decoration-none text-white"
                              >
                                Summary
                              </NavLink>
                            </li> */}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <div className="sidebar_icons icon2">
                            <FiCalendar className="sidebar_icons_width" />
                          </div>
                          <div
                            className="sidebar_text_width my-0"
                            style={{ marginLeft: "20px" }}
                          >
                            Attendance
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <ul>
                            <li className="employee_css">
                              <NavLink
                                to="/attendance/dailylog"
                                className=" text-decoration-none text-white"
                              >
                                Daily Log
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/attendance/details"
                                className=" text-decoration-none text-white"
                              >
                                Attendance Details
                              </NavLink>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          <div className="sidebar_icons icon2">
                            <FiCreditCard className="sidebar_icons_width" />
                          </div>
                          <div
                            className="sidebar_text_width my-0"
                            style={{ marginLeft: "20px" }}
                          >
                            {" "}
                            Payroll
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <ul>
                            <li className="employee_css">
                              <NavLink
                                to="payroll/payslip"
                                className=" text-decoration-none text-white"
                              >
                                Payslip
                              </NavLink>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          <div className="sidebar_icons icon2">
                            <FiBriefcase className="sidebar_icons_width" />
                          </div>
                          <div
                            className="sidebar_text_width my-0"
                            style={{ marginLeft: "20px" }}
                          >
                            {" "}
                            Administration
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <ul>
                            <li className="employee_css">
                              <NavLink
                                to="/administration/departments"
                                className=" text-decoration-none text-white"
                              >
                                Departments
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/administration/holiday"
                                className=" text-decoration-none text-white"
                              >
                                Holiday
                              </NavLink>
                            </li>
                            <li className="employee_css">
                              <NavLink
                                to="/administration/announcements"
                                className=" text-decoration-none text-white"
                              >
                                Announcements
                              </NavLink>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </section>
  );
};
export default LeftMenu;
