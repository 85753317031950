import React, { useEffect, useState } from "react";
// import { useAuth } from "../utils/Context";
import {  useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "react-quill/dist/quill.snow.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BiEdit } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { MultiSelect } from "react-multi-select-component";



const TaskList = ({ menuWidth }) => {
  const { projectid } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const handleEditClose = () => setShowEdit(false);
  const handleEditShow = () => setShowEdit(true);
  /* eslint-disable */
  const [validated, setValidated] = useState(false);
  /* eslint-disable */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selected, setSelected] = useState([]);
  const options = [
    { value: "adesh", label: "Adesh shukla" },
    { value: "Abhishek", label: "Abhishek Gupta" },
    { value: "Arjit", label: "Arjit Singh" },
    { value: "Amit", label: "Amit Singh" },
  ];



  const [data, setData] = useState({
    id: "projectid",
    project_name: "",
    project_link: "",
  });
  useEffect(() => {
    if (projectid) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/project-edit?id=${projectid}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setData(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [projectid]);

  const [tasks, setTasks] = useState([
    {
      id: 1,
      name: "Smarak Samiti - Attendance Management create a form 3",
      status: "New Order",
      time: new Date().toLocaleString(),
      image: "path/to/image",
    },
    {
      id: 2,
      name: "Smarak Samiti - Attendance Management create a form 1",
      status: "In Progress",
      time: new Date().toLocaleString(),
      image: "path/to/image",
    },
    {
      id: 3,
      name: "Smarak Samiti - Attendance Management create a form 2",
      status: "Completed",
      time: new Date().toLocaleString(),
      image: "path/to/image",
    },
  ]);

  // Handles drag start event
  const onDragStart = (evt) => {
    const element = evt.currentTarget;
    element.classList.add("dragged");
    evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
    evt.dataTransfer.effectAllowed = "move";
  };

  // Handles drag end event
  const onDragEnd = (evt) => {
    evt.currentTarget.classList.remove("dragged");
  };

  // Handles drag enter event (while dragging over a drop target)
  const onDragEnter = (evt) => {
    evt.preventDefault();
    const element = evt.currentTarget;
    element.classList.add("dragged-over");
    evt.dataTransfer.dropEffect = "move";
  };

  // Handles drag leave event (when drag leaves the drop target)
  const onDragLeave = (evt) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
  };

  // Handles drag over event (allows dragging over the drop area)
  const onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
  };

  // Handles the drop event (when the task is dropped in a new status column)
  const onDrop = (evt, status) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    const data = evt.dataTransfer.getData("text/plain");

    // Update the task status
    const updatedTasks = tasks.map((task) => {
      if (task.id.toString() === data.toString()) {
        return { ...task, status };
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  // Group tasks based on their status
  const newOrder = tasks.filter((task) => task.status === "New Order");
  const inProgress = tasks.filter((task) => task.status === "In Progress");
  const completed = tasks.filter((task) => task.status === "Completed");

  const data1 = [
    {
      name: "Adesh shukla",
    },
    {
      name: "Abhishek Gupta",
    },
    {
      name: "Arjit Singh",
    },
  ];

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div>
        <div className="d-flex justify-content-between align-items-center pt-4  py-3 w-100">
          <h4 className="m-0"> {data.project_name}</h4>
          <div>
            {/* <button className="btn btn-success puch_btn">Add Project</button> */}
          </div>
        </div>

        <Tabs
          defaultActiveKey="timeline"
          transition={false}
          id="noanim-tab-example"
          className="mb-3 justify-content-start"
        >
          <Tab eventKey="timeline" title="Timeline">
            <form className="d-lg-flex justify-content-between">
              <div className="p-1">
                <label>task</label>
                <input type="text" className="form-control" />
              </div>
              {/* <div className="p-1">
                <label>Assign&nbsp;Employee</label>
                <Select
                  options={options}
                  onChange={handleChange}
                  value={skills}
                  isMulti
                />
              </div> */}
              <div className="p-1" >
                <label>Start Date</label>
                <input type="date" className="form-control" />
              </div>
              <div className="p-1" >
                <label>End Date</label>
                <input type="date" className="form-control" />
              </div>
              <div style={{ width: "120px" }} className="p-1">
                <label>Custom Time</label>
                <input type="text" className="form-control" />
              </div>
              <div className="p-1">
                <label>Select Day/Week/Month</label>
                <select class="form-select" aria-label="Default select example">
                  <option selected>--Select--</option>
                  <option value="1">Today</option>
                  <option value="2">Weeks</option>
                  <option value="3">Months</option>
                  <option value="3">Quarters</option>
                </select>
              </div>
              <div className="p-1">
                <label></label>
                <br />
                <button className="btn btn-primary puch_btn">Add</button>
              </div>
            </form>
            <hr />
            <table className="w-100">
              <thead></thead>
              <tbody>
                <tr className="border-bottom">
                  <td className="py-3">Create Header</td>
                  <td className="py-3">
                    {/* <p className="mb-0">
                          <div class="d-flex justify-content-center">
                            {data1.map((item, index) => (
                              <div className="profile_border_css">
                                {item.name}
                              </div>
                            ))}
                          </div>
                        </p> */}

                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdown_css"
                        variant="link"
                       >
                        <p className="mb-0">
                          <div class="d-flex justify-content-center">
                            {data1.map((item, index) => (
                              <div className="profile_border_css">
                                {item.name.charAt(0)}
                              </div>
                            ))}
                          </div>
                        </p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {data1.map((item, index) => (
                          <Dropdown.Item>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td className="py-3">2024-01-01</td>
                  <td className="py-3">2024-01-10</td>
                  <td className="py-3">40</td>
                  <td className="py-3">
                    <BiEdit
                      className="left_icon_css"
                      onClick={() => handleEditShow()}
                    />
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td className="py-3">Footer fix ui</td>
                  <td className="py-3">
                  <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdown_css"
                        variant="link"
                      >
                        <p className="mb-0">
                          <div class="d-flex justify-content-center">
                            {data1.map((item, index) => (
                              <div className="profile_border_css">
                                {item.name.charAt(0)}
                              </div>
                            ))}
                          </div>
                        </p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {data1.map((item, index) => (
                          <Dropdown.Item>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td className="py-3">2024-01-01</td>
                  <td className="py-3">2024-01-10</td>
                  <td className="py-3">40</td>
                  <td className="py-3">
                    <BiEdit
                      className="left_icon_css"
                      onClick={() => handleEditShow()}
                    />
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td className="py-3">Get Data</td>
                  <td className="py-3">
                  <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdown_css"
                        variant="link"
                      >
                        <p className="mb-0">
                          <div class="d-flex justify-content-center">
                            {data1.map((item, index) => (
                              <div className="profile_border_css">
                                {item.name.charAt(0)}
                              </div>
                            ))}
                          </div>
                        </p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {data1.map((item, index) => (
                          <Dropdown.Item>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td className="py-3">2024-01-01</td>
                  <td className="py-3">2024-01-10</td>
                  <td className="py-3">40</td>
                  <td className="py-3">
                    <BiEdit
                      className="left_icon_css"
                      onClick={() => handleEditShow()}
                    />
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td className="py-3">
                    Smarak Samiti - Attendance Management create a form
                  </td>
                  <td className="py-3">
                  <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdown_css"
                        variant="link"
                      >
                        <p className="mb-0">
                          <div class="d-flex justify-content-center">
                            {data1.map((item, index) => (
                              <div className="profile_border_css">
                                {item.name.charAt(0)}
                              </div>
                            ))}
                          </div>
                        </p>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {data1.map((item, index) => (
                          <Dropdown.Item>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td className="py-3">2024-01-01</td>
                  <td className="py-3">2024-01-10</td>
                  <td className="py-3">40</td>
                  <td className="py-3">
                    <BiEdit
                      className="left_icon_css"
                      onClick={() => handleEditShow()}
                    />
                  </td>
                </tr>
                <tr className="border-bottom">
                  <td className="py-3">
                    Labms Project - Textile Committee fix code{" "}
                  </td>
                  <td className="py-3">
                  <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdown_css"
                        variant="link"
                      >
                        <p className="mb-0">
                          <div class="d-flex justify-content-center">
                            {data1.map((item, index) => (
                              <div className="profile_border_css">
                                {item.name.charAt(0)}
                              </div>
                            ))}
                          </div>
                        </p>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {data1.map((item, index) => (
                          <Dropdown.Item>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td className="py-3">2024-01-01</td>
                  <td className="py-3">2024-01-10</td>
                  <td className="py-3">40</td>
                  <td className="py-3">
                    <BiEdit
                      className="left_icon_css"
                      onClick={() => handleEditShow()}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab eventKey="board" title="Board">
            <div className="container_css">
              <div
                className="column new-order"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDragOver={onDragOver}
                onDrop={(e) => onDrop(e, "New Order")}
              >
                <h4>New Task(To Do)</h4>
                <div className="card_css" onClick={() => handleShow()}>
                  Create New Task +
                </div>
                {newOrder.map((task) => (
                  <div
                    className="card_css"
                    key={task.id}
                    id={task.id}
                    draggable
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                  >
                    <div>
                      <div className="task-details">
                        <div className="d-flex justify-contnet-between">
                          <p className="task-name">{task.name}</p>
                          <PiDotsThreeOutlineVerticalFill
                            className="w-25"
                            onClick={() => handleEditShow()}
                          />
                        </div>
                        <div className="d-flex ">
                          <p className="task-time">From : 26-11-2024</p>
                          <p className="task-time">To : 26-11-2024</p>
                        </div>
                        <h6 className="task-name">
                          work Priority :{" "}
                          <span className="text-success">Schedule</span>
                        </h6>
                        <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdown_css"
                        variant="link"
                      >
                        <p className="mb-0">
                          <div class="d-flex justify-content-center">
                            {data1.map((item, index) => (
                              <div className="profile_border_css">
                                {item.name.charAt(0)}
                              </div>
                            ))}
                          </div>
                        </p>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {data1.map((item, index) => (
                          <Dropdown.Item>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* In Progress Column */}
              <div
                className="column in-progress"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDragOver={onDragOver}
                onDrop={(e) => onDrop(e, "In Progress")}
              >
                <h4>In Progress</h4>
                {inProgress.map((task) => (
                  <div
                    className="card_css"
                    key={task.id}
                    id={task.id}
                    draggable
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                  >
                    <div>
                      <div className="task-details">
                        <div className="d-flex justify-contnet-between">
                          <p className="task-name">{task.name}</p>
                          <PiDotsThreeOutlineVerticalFill className="w-25" />
                        </div>
                        <div className="d-flex">
                          <p className="task-time">From : 26-11-2024</p>
                          &nbsp;
                          <p className="task-time"> To : 26-11-2024</p>
                        </div>
                        <p className="task-name">
                          work Priority :{" "}
                          <span className="text-danger">Urgent</span>
                        </p>
                        {/* <p className="task-time"> Links:<ol>
                          <li>https://getbootstrap.com/docs/5.0/forms/select/</li>
                          <li>https://getbootstrap.com/docs/5.0/forms/select/</li>
                          <li>https://getbootstrap.com/docs/5.0/forms/select/</li>
                          <li>https://getbootstrap.com/docs/5.0/forms/select/</li>
                          <li>https://getbootstrap.com/docs/5.0/forms/select/</li>
                          </ol>
                          </p> */}
                           <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdown_css"
                        variant="link"
                      >
                        <p className="mb-0">
                          <div class="d-flex justify-content-center">
                            {data1.map((item, index) => (
                              <div className="profile_border_css">
                                {item.name.charAt(0)}
                              </div>
                            ))}
                          </div>
                        </p>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {data1.map((item, index) => (
                          <Dropdown.Item>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Completed Column */}
              <div
                className="column completed"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDragOver={onDragOver}
                onDrop={(e) => onDrop(e, "Completed")}
              >
                <h4>Completed</h4>
                {completed.map((task) => (
                  <div
                    className="card_css"
                    key={task.id}
                    id={task.id}
                    draggable
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                  >
                    <div>
                      <div className="task-details">
                        <div className="d-flex justify-contnet-between">
                          <p className="task-name">{task.name}</p>
                          <PiDotsThreeOutlineVerticalFill className="w-25" />
                        </div>
                        <div className="d-flex ">
                          <p className="task-time">From : 26-11-2024</p>
                          <p className="task-time">To : 26-11-2024</p>
                        </div>
                        <h6 className="task-name">
                          work Priority :{" "}
                          <span className="text-warning">Delegate</span>
                        </h6>
                        <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdown_css"
                        variant="link"
                      >
                        <p className="mb-0">
                          <div class="d-flex justify-content-center">
                            {data1.map((item, index) => (
                              <div className="profile_border_css">
                                {item.name.charAt(0)}
                              </div>
                            ))}
                          </div>
                        </p>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {data1.map((item, index) => (
                          <Dropdown.Item>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>

      {/* -------------------Assign Task------------------------- */}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Assign Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Task Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter project name"
                // value={addProject.project_name}
                // onChange={(e) =>
                //   setAddProject({ ...addProject, project_name: e.target.value })
                // }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter project name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Task Details</Form.Label>
              <Form.Control
                type="textarea"
                placeholder="Enter project name"
                // value={addProject.project_name}
                // onChange={(e) =>
                //   setAddProject({ ...addProject, project_name: e.target.value })
                // }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter project name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Assign Employee</Form.Label>
              {/* <Select
                options={options}
                onChange={handleChange}
                value={skills}
                isMulti
              /> */}

<MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy={"Select"}
        isCreatable={true}
      />
              <Form.Control.Feedback type="invalid">
                Please enter project name.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex">
              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Status</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter project name"
                  // value={addProject.project_name}
                  // onChange={(e) =>
                  //   setAddProject({
                  //     ...addProject,
                  //     project_name: e.target.value,
                  //   })
                  // }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter project name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Work Priority</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter project name"
                  // value={addProject.project_name}
                  // onChange={(e) =>
                  //   setAddProject({
                  //     ...addProject,
                  //     project_name: e.target.value,
                  //   })
                  // }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter project name.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="d-lg-flex">
              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter project URL"
                  // value={addProject.project_link}
                  // onChange={(e) =>
                  //   setAddProject({
                  //     ...addProject,
                  //     project_link: e.target.value,
                  //   })
                  // }
                />
              </Form.Group>
              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter project URL"
                  // value={addProject.project_link}
                  // onChange={(e) =>
                  //   setAddProject({
                  //     ...addProject,
                  //     project_link: e.target.value,
                  //   })
                  // }
                />
              </Form.Group>
            </div>
            <div className="d-lg-flex">
              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Select Day/Week/Month</Form.Label>

                <select class="form-select" aria-label="Default select example">
                  <option selected>--Select--</option>
                  <option value="1">Today</option>
                  <option value="2">Weeks</option>
                  <option value="3">Months</option>
                  <option value="3">Quarters</option>
                </select>
                <Form.Control.Feedback type="invalid">
                  Please enter project name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Custom Time</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter project name"
                  // value={addProject.project_name}
                  // onChange={(e) =>
                  //   setAddProject({
                  //     ...addProject,
                  //     project_name: e.target.value,
                  //   })
                  // }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter project name.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            {/* {addProject.error && (
              <Alert variant="danger">{addProject.error}</Alert>
            )} */}

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              &nbsp;
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/* -----------------Edit project-------------------------- */}
      <Modal show={showEdit} onHide={handleEditClose} size="lg">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Edit Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Task Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter project name"
                // value={addProject.project_name}
                // onChange={(e) =>
                //   setAddProject({ ...addProject, project_name: e.target.value })
                // }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter project name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Task Details</Form.Label>
              <Form.Control
                type="textarea"
                placeholder="Enter project name"
                // value={addProject.project_name}
                // onChange={(e) =>
                //   setAddProject({ ...addProject, project_name: e.target.value })
                // }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter project name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Assign Employee</Form.Label>
              <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy={"Select"}
        isCreatable={true}
      />
              <Form.Control.Feedback type="invalid">
                Please enter project name.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-lg-flex">
              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Status</Form.Label>

                <select class="form-select" aria-label="Default select example">
                  <option selected>--Select--</option>
                  <option value="1">To do</option>
                  <option value="2">Inprogress</option>
                  <option value="3">Done</option>
                </select>
                <Form.Control.Feedback type="invalid">
                  Please enter project name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Work Priority</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter project name"
                  // value={addProject.project_name}
                  // onChange={(e) =>
                  //   setAddProject({
                  //     ...addProject,
                  //     project_name: e.target.value,
                  //   })
                  // }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter project name.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="d-lg-flex">
              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter project URL"
                  // value={addProject.project_link}
                  // onChange={(e) =>
                  //   setAddProject({
                  //     ...addProject,
                  //     project_link: e.target.value,
                  //   })
                  // }
                />
              </Form.Group>
              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter project URL"
                  // value={addProject.project_link}
                  // onChange={(e) =>
                  //   setAddProject({
                  //     ...addProject,
                  //     project_link: e.target.value,
                  //   })
                  // }
                />
              </Form.Group>
            </div>

            <div className="d-lg-flex">
              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Select Day/Week/Month</Form.Label>

                <select class="form-select" aria-label="Default select example">
                  <option selected>--Select--</option>
                  <option value="1">Today</option>
                  <option value="2">Weeks</option>
                  <option value="3">Months</option>
                  <option value="3">Quarters</option>
                </select>
                <Form.Control.Feedback type="invalid">
                  Please enter project name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3 w-100 px-1"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Custom Time</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter project name"
                  // value={addProject.project_name}
                  // onChange={(e) =>
                  //   setAddProject({
                  //     ...addProject,
                  //     project_name: e.target.value,
                  //   })
                  // }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter project name.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            {/* {addProject.error && (
        <Alert variant="danger">{addProject.error}</Alert>
      )} */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleEditClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary puch_btn"
            // onClick={handleSaveProject}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};
export default TaskList;
